// import { env } from '../../../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { PageComponent } from './page.component';

import {UtilModule} from '../../helper/Util.module';

@NgModule({
  declarations: [PageComponent],
  imports: [
    CommonModule,
    // FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: 'page/:tag1',
        component: PageComponent
      },
      {
        path: 'page/:tag1/:tag2',
        component: PageComponent
      }
    ]),
    HttpClientModule,
    UtilModule,
  ]
})
export class PagePageModule {}
