import { env } from '../environments/version';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-title',
  template: 'v{{version}}',
})

export class TitleComponent {

  public version: string;

  constructor(
  //  private platform: Platform,
  ) {
    this.version = env.version;
  }
}
