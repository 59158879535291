import { popoverController } from '@ionic/core';

import { Router } from '@angular/router';
import { env } from '../environments/version';
import { Component, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';

// import { Platform } from "@ionic/angular";
import { PreferenceService } from './services/preference.service';
import { PagesService } from './services/pages.service';
import { AppComponent } from './app.component';

import { PasswordComponent } from './password.component';
import { Platform, PopoverController } from '@ionic/angular';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.component.html'
})

export class TabsComponent {
  @Input() mode = 'content';
  @Input() page: any;

  public languages = ['FR', 'DE', 'EN'];
  public nbClick = 0;

  constructor(
    // private platform: Platform,
    public router: Router,
    public preferenceService: PreferenceService,
    public appComponent: AppComponent,
    public pagesService: PagesService,
    public toastController: ToastController,
    public popoverController: PopoverController
  ) {
  /*
    customElements.define('popover-nip', class ModalContent extends HTMLElement {
      constructor() {
        super();
       }

      public psw = 'OK';

      connectedCallback() {
        this.innerHTML = `
          <ion-list>
            <!--
            <ion-list-header>Password</ion-list-header>
            -->
            <ion-input type="password" placeholder="Enter NIP" [(ngModel)]="psw"></ion-input>
            <ion-item lines="none" detail="false" button onClick="dismiss()">OK</ion-item>
            <!--
            <ion-item button>Documentation</ion-item>
            <ion-item button>Showcase</ion-item>
            <ion-item button>GitHub Repo</ion-item>
            
            <ion-button>Cancel</ion-button><ion-button>OK</ion-button>
            -->
          </ion-list>
        `;
      }
    });
  */
  }

  // pa1-1
  gotoNextPage(event) {
    if (event) {
      event.preventDefault();
    }

    const nextPage = this.getNextPage();
    this.router.navigate(['/page/' + nextPage], {});
  }

  gotoMiddlePage(event) {
    if (event) {
      event.preventDefault();
    }

    const nextPage = this.getMiddlePage();
    this.router.navigate(['/page/' + nextPage], {});
  }

  gotoPrevPage(event) {
    if (event) {
      event.preventDefault();
    }

    const prevPage = this.getPrevPage();
    this.router.navigate(['/page/' + prevPage], {});
  }

  getPrevPage() {
    const p = this.page.slug.substring(0, 2); // pa
    const n = this.page.slug.substring(2); // 1-1
    const na = n.split('-');
    if (na.length === 1) {
      return null;
    } else {
      return p + na[0] + '-' + (--na[1]);
    }
  }

  getNextPage(): string {
    const p = this.page.slug.substring(0, 2); // pa
    const n = this.page.slug.substring(2); // 1-1
    const na = n.split('-');
    if (na.length === 1) {
      return p + na[0] + '-s';
    } else {
      return p + na[0] + '-' + (++(na[1]));
    }
  }

  getMiddlePage(): string {
    const p = this.page.slug.substring(0, 2); // pa
    const n = this.page.slug.substring(2); // 1-1
    const na = n.split('-');
    // if (na.length === 1) {
    return p + na[0] + '-s';
    // } else {
    //  return null;
    // }
  }

  isMiddlePage(): boolean {
    // const pages = this.pagesService.pages;
    const slug = this.getMiddlePage();
    return !!this.pagesService.pages.find(page => page.slug === slug);
  }

  isNextPage(): boolean {
    // const pages = this.pagesService.pages;
    const slug = this.getNextPage();
    return !!this.pagesService.pages.find(page => page.slug === slug);
  }

  isPrevPage(): boolean {
    const pages = this.pagesService.pages;
    const slug = this.getPrevPage();
    return !!pages.find(page => page.slug === slug);
  }

  // alias
  public gotoHome(event) {
    this.appComponent.gotoHome(event);
  }

  // alias
  public changeLanguage(language) {
    this.preferenceService.changeLanguage(language);
  }

  public clickforAdmin(ev: any) {
    
    // You have 2 seconds to click 3 times
    if (this.nbClick++ === 0) {
      setTimeout(() => this.nbClick = 0, 2000);
    }
    if (this.nbClick === 3 || this.preferenceService.preference.adminMode) {
      this.nbClick = 0;
      console.log('Switch now');
      this.switchNow(ev);
    }
  }

  async switchNow(ev: any) {

    const correctPsw = '4321';

    var popover = await this.popoverController.create({
      component: PasswordComponent,
      event: ev,
      cssClass: 'popover_password',
      //autofocus: true,
      componentProps: {
        correctPsw: correctPsw
      },
      translucent: true
    });

    popover.onDidDismiss().then((result) => {
      popover = null;
      if (result.data && result.data === correctPsw) {
        this.switchNow2();
      }
    });

    await popover.present();

    setTimeout(() => {
      popover && popover.dismiss();
      }, 10000);
  }

  async switchNow2() {

    this.preferenceService.preference.adminMode = !this.preferenceService.preference.adminMode;

    const msg = 'Switch to mode: ' + (this.preferenceService.preference.adminMode ? 'admin' : 'user');

    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();

    this.preferenceService.save();
  }

}

