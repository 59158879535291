// import { env } from '../../../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { PagesComponent } from './pages.component';

import {UtilModule} from '../../helper/Util.module';

@NgModule({
  declarations: [PagesComponent],
  imports: [
    CommonModule,
    // FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: 'pages',
        component: PagesComponent
      }
    ]),
    HttpClientModule,
    UtilModule
  ]
})
export class PagesPageModule {}
