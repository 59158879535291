import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { MediasComponent } from './medias.component';

import {UtilModule} from '../../helper/Util.module';

//import { FileSaverModule } from 'ngx-filesaver';

@NgModule({
  declarations: [MediasComponent],
  imports: [
//    FileSaverModule,
    CommonModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: 'medias',
        component: MediasComponent
      }
    ]),
    HttpClientModule,
    UtilModule
  ]
})
export class MediasPageModule {}
