import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { env } from '../../environments/environment';
import { PreferenceService } from './preference.service';

import * as fs from 'fs-web';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private files: Array<File>;
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private preferenceService: PreferenceService
  ) {
    console.log('construtor: CacheService');

    this.baseUrl = this.preferenceService.preference.backEndUrl;

    const files = localStorage.getItem('files');

    if (files) {
      // console.debug("files loaded from localStorage");
      this.files = JSON.parse(files);
    }
  }

  public  downloadFile(
    url: string,
    filename: string,
    force: boolean
  ): Observable<Blob> {
    const ret = new Observable<Blob>((observer) => {
      this.processFetch(url, filename, force)
        .then((blob) => {
          if (blob && blob.size > 0) {
            observer.next(blob);
          } else {
            observer.next(null);
          }
          
          observer.complete();
        })
        .catch((error) => {
          // observer.error(error);
          if (env.debug) { console.log('cache error: ' + error); }
        });
    });

    return ret;
  }

  async processFetch(url, fileName: string, force: boolean): Promise<Blob> {
    let cacheBlob: Blob = null;

    // Try to read file before re-download
    if (!force) {
      await this.readFile(fileName).then((blob) => {
        if (blob && blob.size > 0) {
          console.log('file exits: ' + fileName);
          cacheBlob = blob;
        }
      });
    }

    if (!!cacheBlob) {
      return cacheBlob;
    } else {
      await fetch(url, {
        headers: {},
      })
        .then((response: any) => {
          return response.blob();
        })
        .then((blob: Blob) => {
          if (blob && blob.size > 0) {
            fs.writeFile(fileName, blob).then(() => {
              // return fs.readString(fileName);
            });
          } else {
            console.error("Unable to download (CORS ERROR?): " + fileName);
            blob = null;
          }
          return blob;
        })
        .catch((error) => {
          // return error;
          throw new Error(error);
        });
    }
  }

  async readFile(fileName: string): Promise<Blob> {
    let cacheBlob: Blob;
    await fs
      .readString(fileName)
      .then((blob) => {
        cacheBlob = blob;
      })
      .catch((error) => {
        //if (env.debug) { 
          console.error('File does\'nt not exists now: ' + fileName); 
        //}
      });
    return cacheBlob;
  }
}
