import { Injectable, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PreferenceService } from './preference.service';
import { CacheService } from './cache.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MediasService implements OnDestroy {
  public medias: Array<Media>;
  public files: Array<File>;
  public filesUrls: object;
  private filePath = 'medias';
  public refreshing: boolean;
  public filesDownloadedCount = 0;

  @Output() urlAssigned: EventEmitter<any> = new EventEmitter();

  // private myInterval: any;

  constructor(
    private http: HttpClient,
    private preferenceService: PreferenceService,
    private cacheService: CacheService,
    private dom: DomSanitizer
  ) {
    console.log('construtor: MediasService');

    this.refresh(false);

    // Survey of clear Storage
    // this.myInterval = setInterval(() => {
    //   console.log('Check Clear Storage Medias every 10 sec');
    //   if (localStorage.getItem('pages') === null) {
    //     console.log('Clear Storage detected');
    //     this.refresh(false);
    //   }
    // }, 10000);

    this.filesUrls = {};
    this.files.forEach((file) => {
      this.downloadAndInit(null, file);
    });
  }

  public refresh(force: boolean) {
    console.log('Refesh medias');
    if (this.refreshing) {
      return;
    }

    this.refreshing = true;

    const mediass = localStorage.getItem('medias');
    if (!this.medias) {
      if (mediass) {
        // console.log("medias loaded from localStorage");
        this.medias = JSON.parse(mediass);
      } else {
        this.medias = [];
      }  
    } else {
      if (!mediass) {
        this.medias = [];
      }
    }

    const filess = localStorage.getItem('files');
    if (!this.files) {
      
      if (filess) {
        // console.log("files loaded from localStorage");
        this.files = JSON.parse(filess);
      } else {
        this.files = [];
      }  
    } else {
      if (!filess) {
        this.files = [];
      }
    }

    // this.medias = null;
    const baseUrl = this.preferenceService.preference.backEndUrl;
    const rurl = baseUrl + 'wp-json/wp/v2/media';
    this.filesDownloadedCount = 0;

    this.http.get<string>(rurl).subscribe(
      (result) => {
        const medias: Array<object> = (result as unknown) as Array<Media>;
        const ps = medias.sort((a, b) => +a['id'] - b['id']) as Array<object>;

        const files: Array<File> = [];

        ps.forEach((element) => {
          let url = element['source_url'];
          let fileName = this.getFilenameFromUrl(url);

          let element_sizes = 0;
          if (
            element['media_details'] &&
            element['media_details']['sizes']
          ) {
            const sizes: any = element['media_details']['sizes'];

            for (const key in sizes) {
              element_sizes++;
            }
          }

          const media: Media = {
            id: element['id'],
            slug: element['slug'],
            title: element['title']['rendered'],
            caption: element['caption']['rendered'],
            description: element['description']['rendered'],
            url,
            localFilename: fileName,
            modified: element['modified'],
            mime_type: element['mime_type'],
            sizes: element_sizes
          };

          // Check for modified
          let modified = true;

          if (this.medias) {
            const ms = this.medias.filter((x) => x.id === media.id);
            if (ms.length > 0) {
              const m = ms[0].modified;
              modified = (m !== media.modified) || (ms[0].sizes || 0) !== media.sizes; // new size
            }
          }
          if (!modified && !this.filesUrls[fileName]) {
            modified = true;
          }


          if (modified || force) {
            const f = new File(fileName, url);
            files.push(f);
            this.pushFileIfNotExists(this.files, f);
            // console.log("Download file: " + result);
            this.downloadAndInit(files, f);

            if (
              //force &&
              element['media_details'] &&
              element['media_details']['sizes']
            ) {
              const sizes: any = element['media_details']['sizes'];

              for (const key in sizes) {
                if (Object.prototype.hasOwnProperty.call(sizes, key)) {
                  const el = sizes[key];
                  url = el.source_url;

                  console.log('Download file: ' + fileName);
                  fileName = this.getFilenameFromUrl(url);
                  const f2 = new File(fileName, url);
                  this.pushFileIfNotExists(this.files, f2);
                  files.push(f2); // just to count
                  this.downloadAndInit(files, f2);
                }
              }
            }

            if (modified) {
              // Remove old version
              this.medias = this.medias.filter(obj => obj.id !== media.id);
            }

            this.pushMediaIfNotExists(this.medias, media);
          }
        });

        if (files.length) {
          localStorage.setItem('medias', JSON.stringify(this.medias));
          localStorage.setItem('files', JSON.stringify(this.files));
        } else {
          this.refreshing = false;
        }
      },
      (error) => {
        console.error(error);
        this.refreshing = false;
      }
    );
  }

  private downloadAndInit(files: Array<File>, file: File) {
    this.cacheService.downloadFile(file.url, file.filename, false).subscribe(
      (blob) => {
        // console.log("File downloaded: " + f.filename);
        this.initFile(file, blob).then(() => {
          if (files !== null) {
            this.filesDownloadedCount++;
            if (files.length === this.filesDownloadedCount) {
              this.refreshing = false;
            }
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public pushFileIfNotExists(files: Array<File>, file: File) {
    const exists = files.find((ef) => {
      return ef.filename === file.filename;
    });

    if (!exists) {
      files.push(file);
    }
  }

  public pushMediaIfNotExists(medias: Array<Media>, media: Media) {
    const exists = medias.find((mf) => {
      return mf.id === media.id;
    });

    if (!exists) {
      medias.push(media);
    }
  }

  public getFilenameFromUrl(url: string) {
    return `${this.filePath}/${url.split('/').reverse()[0]}`;
  }

  // Destructor
  ngOnDestroy() {
    console.log('MediasService::ngOnDestroy');

    for (const key in this.filesUrls) {
      if (Object.prototype.hasOwnProperty.call(this.filesUrls, key)) {
        const element = this.filesUrls[key];
        (URL || webkitURL).revokeObjectURL(element);
      }
    }

    this.filesUrls = {};
  }

  private async initFile(file: File, blob: Blob = null) {
    if (!this.filesUrls[file.filename]) {
      console.log('initFile: start: ' + file.filename);
      if (!blob) {
        this.cacheService
          .downloadFile(file.url, file.filename, false)
          .subscribe(
            (next) => {
              blob = next;
              console.log('initFile: end: ' + file.filename);

              if (blob) {
                if (blob.type === 'text/html') {
                  console.error('Blob text/html ignored for src');
                } else {
                  this.initBlobObject(file, blob);
                }
              }
              
            },
            (error) => {
              console.error('initFile Error: ' + error);
            }
          );
      } else {
        this.initBlobObject(file, blob);
      }
    }
  }
  private initBlobObject(file: File, blob: Blob) {
    if (blob && blob.size > 0) {
      const unsafeUrl = (URL || webkitURL).createObjectURL(blob);
      const safeurl = this.dom.bypassSecurityTrustUrl(unsafeUrl);
      this.filesUrls[file.filename] = safeurl;
      this.urlAssigned.emit({
        filename: file.filename,
        url: safeurl,
      });
    } else {
      console.error('Blob invalid for: ' + file.filename);
    }
  }

  public clear() {
    for (const key in this.filesUrls) {
      if (Object.prototype.hasOwnProperty.call(this.filesUrls, key)) {
        const element = this.filesUrls[key];
        (URL || webkitURL).revokeObjectURL(element);
      }
    }

    this.filesUrls = {};
    this.files = [];
    this.medias = [];
  }
}

export class Media {
  id: number;
  slug: string;
  title: string;
  // tslint:disable-next-line: variable-name
  mime_type: string;
  caption: string;
  description: string;
  url: string;
  localFilename: string;
  modified: string;
  sizes: number;
}

export class File {
  constructor(public filename: string, public url: string) {}
}
