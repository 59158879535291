import { env } from '../environments/version';
import { Component, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';


@Component({
  //selector: 'app-password',
  // TODO: text center not work
  template: `
  <ion-list>
    <ion-input type="password" placeholder="NIP" [(ngModel)]="psw" (ngModelChange)="pswChange()" maxlength="4" autofocus></ion-input>
    <ion-item text-center lines="none" detail="false" button (click)="onDismiss()">OK</ion-item>
  </ion-list>
  `
})

export class PasswordComponent {
  psw: string;
  correctPsw: string;

  constructor(public popoverController: PopoverController) { }

  async onDismiss() {
    await this.popoverController.dismiss(this.psw);
  }

  async pswChange() {
    if (this.psw.length === 4 && this.psw === this.correctPsw) {
      await this.popoverController.dismiss(this.psw);
    }
  }
}
