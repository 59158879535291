// import { env } from "../../../environments/environment";
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastController } from '@ionic/angular';

// import { Url } from "url";

import { PreferenceService, Preference } from '../../services/preference.service';
import { MediasService } from '../../services/medias.service';
import { PagesService } from '../../services/pages.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.page.html',
  styleUrls: ['./preference.page.scss'],
})
export class PreferencePage implements OnInit, OnDestroy {

  public preference: Preference;
  private preference$: Subscription;

  public pages = [];

  changeCounter = 0;
  savedChangeCounter = 0;

  constructor(
    public toastController: ToastController,
    public preferenceService: PreferenceService,
    public mediasService: MediasService,
    public pagesService: PagesService,
  ) {
    // with [ngFormControl]="ctrl"
    // this.ctrl = new Control('', (control) => {
    //   // validate the value
    // });

    // this.ctrl.valueChanges.subscribe((value) => {
    //   // called when the value is updated
    // });
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Your settings have been saved.',
      duration: 2000
    });
    toast.present();
  }

  ngOnInit() {
    this.preference = this.preferenceService.peekPreference();
    this.preference$ = this.preferenceService.watchPreference().subscribe((observer) => {
      console.log('settings preferenceService.watch...', observer);
      this.preference = this.preferenceService.peekPreference();
    });

    this.refresh();

  }

  ngOnDestroy() {
    this.preference$.unsubscribe();
  }

  public doSave(event: any) {
    ++this.changeCounter;
    setTimeout(() => {
      if (this.savedChangeCounter !== this.changeCounter) {
        this.savedChangeCounter = this.changeCounter;
        this.preferenceService.save(this.preference).then(
          () => {
            this.presentToast();
          }
        );
      }
    }, 1000);
  }

  public refresh() {
    this.pagesService.refresh(true);
    this.mediasService.refresh(true);

    this.pages = this.pagesService.pages.filter(
      (x) => x.parent === 0
    );

  }

}
