import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener
} from '@angular/core';

import { IonContent } from '@ionic/angular';

import { Observable, Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { PagesService, Page } from './../../services/pages.service';
import { MediasService, Media } from './../../services/medias.service';
import { PreferenceService } from '../../services/preference.service';

// import { TabsComponent } from '../../tabs.component';

import { DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { AppComponent } from '../../app.component';


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  // @ViewChild('content', { static: false }) content: any;

  @ViewChild(IonContent, { static: false }) protected content: any;

  public lng: string;
  private sub: any;
  public slug: string;
  public page: Page;
  private serviceSubscription: Subscription;

  public hasScrollbar = false;
  differ: KeyValueDiffer<string, any>;



  // checks if there's a scrollbar when the user resizes the window or zooms in/out
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForScrollbar();
    this.app.resetInterval();
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public pagesService: PagesService,
    public mediasService: MediasService,
    public preferenceService: PreferenceService,
    public differs: KeyValueDiffers,
    public app: AppComponent,
  ) {
    this.lng = this.preferenceService.lng;
    this.differ = this.differs.find({}).create();
    const multicastSequence = new Observable(preferenceService.languageSubscriber());

    multicastSequence.subscribe((next: string) => {
      console.log('Change page language to: ' + next);
      this.lng = next;
      this.app.resetInterval();
    });

    this.serviceSubscription = this.mediasService.urlAssigned.subscribe({
      next: (event: any) => {
        console.log("Received message urlAssigned", event);

        const mediaEls = document.querySelectorAll('img,video,audio');

        for (let index = 0; index < mediaEls.length; index++) {
          const element: (Element | any) = mediaEls[index];
          const src = element.attributes.src;

          // Not needed if angular img/video/sound
          if (src) {
            // const file_path = 'medias';
            const url = src.value;
            // const fileName = `${file_path}/${url.split('/').reverse()[0]}`;
            const fileName = this.mediasService.getFilenameFromUrl(url);

            if (fileName === event.filename) {
              const newValue = event.url.toString().split(' ')[4];
              console.log('set src:' + newValue);
              element.attributes.src.value = newValue;
            }
          }
        }
      },
    });
  }

  ngAfterViewInit() {
    if (this.content) {
      const routes = this.content.el.getElementsByClassName('mp_route');
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < routes.length; index++) {
        const element: (HTMLBaseElement | any) = routes[index];
        const routr = this.router;
        const rl = this.route;
        element.onclick = function (ev) {
          ev.preventDefault();
          const r: string = element.attributes.href.value;
          routr.navigate([r], { relativeTo: rl });
        };
      }
      const mediaEls = document.querySelectorAll('img,video,audio') as unknown as Array<Element | any>;


      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < mediaEls.length; index++) {
        const element = mediaEls[index];

        // TODO: Add preference
        if (element.tagName === 'VIDEO' && this.preferenceService.preference.muteAllVideo) {
          element.muted = true;
        }

        // Remove srcset for now TODO: Support srcset
        if (element.attributes.srcset) {
          element.removeAttribute('srcset');
        }

        const src = element.attributes.src;
        if (src) {
          const url = src.value;
          const fileName = this.mediasService.getFilenameFromUrl(url);
          const newValue = this.mediasService.filesUrls[fileName];
          if (newValue) {
            // trick to get the blob url ! TODO: Review this sh
            const newValue2 = newValue.toString().split(' ')[4];
            element.attributes.src.value = newValue2;
            console.log(`set media src: ${src} -> ${newValue2}`);
          }
        }
      }
      this.reviewScrollbarLayout(this.content);
      this.checkForScrollbar();
    } else {
      console.error('Content is null');
    }
  }

  reviewScrollbarLayout(content) {
    const main = async () => {

      //console.log("reviewScrollbarLayout");

      //let content = document.querySelector('ion-content');
      //let content = el.el; //document.querySelector('ion-content');
      const styles = document.createElement('style');
      
      styles.textContent = `
        ::-webkit-scrollbar {
          width: 1vw;
        }
    
        /* Track */
        ::-webkit-scrollbar-track {
          /* box-shadow: inset 0 0 5px grey; */
          box-shadow: none; 
          /*border-radius: 10px;*/
        }
    
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #cec0c090;
          border-radius: 0.5vw;
        }
    
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #cec0c0ff; 
        }
      `;
      content.el.shadowRoot.appendChild(styles);
    };
    main();
    /*
    setTimeout(() => {
      main();
    }, 250);
    */
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params: any) => {
      this.slug = params.tag1 || '';
      this.slug = params.tag2 || this.slug;

      if (this.slug) {
        console.log('Page slug: ' + this.slug);

        // this.page = this.pagesService.pages.filter(x => x.id == this.id)[0];
        this.page = this.pagesService.pages.filter(
          (x) => x.slug === this.slug
        )[0];
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.serviceSubscription ) {
      this.serviceSubscription.unsubscribe();
    }
  }

  scrollToTop() {
    console.log('scrollTop');
    this.content.scrollToTop(1000);
  }

  async checkForScrollbar() {
    console.log('checkForScrollbar');
    if (!this.content) {
      console.log('no content');
      return;
    }
    const scrollElement = await this.content.getScrollElement();
    this.hasScrollbar = (scrollElement.scrollHeight - 50) > scrollElement.clientHeight;
    if (!this.hasScrollbar) {
      let me = this;
      setTimeout(() => {
        me.hasScrollbar = (scrollElement.scrollHeight - 50) > scrollElement.clientHeight;
      }
        , 1000);
    }
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        console.log('item changed: ' + item.key, item);
        if (item.key === 'lng') {
          this.checkForScrollbar();
        }
      });
    }
  }
}
