import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PreferencePage } from './preference.page';

/*
const routes: Routes = [
  {
    path: 'settings',
    component: PreferencePage
  }
];
*/

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: 'settings',
        component: PreferencePage
      }
    ])
  ],
  declarations: [PreferencePage]
})
export class PreferencePageModule {}
