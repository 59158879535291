import { env } from '../../environments/version';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';

import { PreferenceService } from '../services/preference.service';
import { MediasService } from './medias.service';
import { PagesService } from './pages.service';
/*
@Injectable({
  providedIn: 'root'
})
*/

@Injectable()
export class VersionCheckService {
  public currentVersion: string = env.version;
  private alertOn = false;
  private isChecking = false;
  private url = 'version.json';

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private preferenceService: PreferenceService,
    public mediasService: MediasService,
    public pagesService: PagesService,
  ) { }

  /**
   * Checks in every set frequency the version of frontend application
   * @param frequency - in milliseconds, defaults to 1 minutes
   */
  public initVersionCheck(frequency = 1000 * 10 * 1 /*min*/) {
    setInterval(() => {

      this.preferenceService.refresh();
      
      console.log('Check Clear Storage every 10 sec');
      if (localStorage.getItem('pages') === null) {
        console.log('Clear Storage Pages detected');
        this.pagesService.refresh(true);
      } else {
        this.pagesService.refresh(false);
      }

      if (localStorage.getItem('medias') === null) {
        console.log('Clear Storage Medias detected');
        this.mediasService.refresh(true);
      } else {
        this.mediasService.refresh(false);
      }

      if (this.preferenceService.preference.autoUpdate) {
        if (!this.isChecking) {
          this.checkVersion();
        }

      }
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   */
  private checkVersion() {
    this.isChecking = true;
    // timestamp these requests to invalidate caches
    this.http
      .get(this.url + '?t=' + new Date().getTime())
      // .first()
      .subscribe(
        (response: any) => {
          const remoteVersion = response.version;
          if (!this.currentVersion) {
            this.currentVersion = remoteVersion;
          } else {
            // If new version, do something
            if (remoteVersion !== this.currentVersion) {
              // Display nice Alert
              if (!this.alertOn) {
                if (this.preferenceService.preference.confirmNewUpdate) {
                  this.presentConfirm().then(() => {
                    this.isChecking = false;
                  });
                } else {
                  window.location.reload();
                  this.isChecking = false;
                }
              }
            } else {
              this.isChecking = false;
            }
          }
        },
        err => {
          console.error(err, 'Could not get version');
          this.isChecking = false;
        }
      );
  }
  async presentConfirm() {
    this.alertOn = true;
    const alert = await this.alertCtrl.create({
      // title: 'Information',
      message:
        'You just receive a new version of the Application, do you want to update now?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.alertOn = false;
          }
        },
        {
          text: 'Update',
          handler: () => {
            console.log('Update clicked');
            this.alertOn = false;
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }
}
