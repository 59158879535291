import { env } from '../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
// import { Platform, MenuController } from '@ionic/angular';

import { AppComponent } from '../app.component';

import { PageComponent } from '../pages/page/page.component';

import { ActivatedRoute, Router } from '@angular/router';
import { PagesService, Page } from './../services/pages.service';
import { MediasService, Media } from './../services/medias.service';
import { PreferenceService } from '../services/preference.service';

// import { NativeAudio } from '@ionic-native/native-audio';

import { AlertController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage
  extends PageComponent
  implements OnInit, OnDestroy, DoCheck {

  public mode: string; // = 'media'; // or content
  private mode$: Subscription;

  private pages$: Subscription;

  private preference$: Subscription;

  private routerSubscription$: Subscription;

  public media: Media;

  public refreshing = false;

  private loaded = false;

  private timeStamp;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public pagesService: PagesService,
    public mediasService: MediasService,
    public preferenceService: PreferenceService,
    private alertCtrl: AlertController,
    private appComponent: AppComponent,
    public differs: KeyValueDiffers,
  ) {
    super(
      route,
      router,
      pagesService,
      mediasService,
      preferenceService,
      differs,
      appComponent
    );

    this.timeStamp = (new Date()).getTime();
  }

  ngOnInit() {
    if (env.debug) {
      console.log('Home: OnInit');
    }

    this.preference$ = this.preferenceService.watchPreference().subscribe((observer) => {
      console.log('home preferenceService.watch...', observer);
      this.refresh();
    });

    this.mode = this.pagesService.peekMode();
    this.mode$ = this.pagesService.watchMode().subscribe((observer) => {
      console.log('Home pagesService.watchMode().subscribe((observer)', observer);
      this.mode = observer;
      this.refresh();
    });

    this.pages$ = this.pagesService.watchPages().subscribe((observer) => {
      console.log('Home pagesService.watchPages().subscribe((observer)', observer);

      if (observer && this.router.url === '/') {

        setTimeout(() => {
          this.refresh();
          this.preferenceService.changeLanguage('FR');
          this.pagesService.pokeMode('content');
          this.pagesService.pokeMode('media');
        }, 2000);
      }
    });

    // Here we put in place a feature to detect router change to clear the timeout for gotoHome
    this.routerSubscription$ = this.router.events.subscribe((val) => {
      // console.log('Router change', val);
      this.appComponent.resetInterval();
    });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  public getLinkPicture(media: Media) {
    if (this.mediasService.filesUrls[media.localFilename]) {
      return this.mediasService.filesUrls[media.localFilename];
    } else {
      return media.url;
    }
  }

  ngOnDestroy() {
    this.preference$.unsubscribe();
    this.mode$.unsubscribe();
    this.pages$.unsubscribe();
    this.routerSubscription$.unsubscribe();
  }

  public refresh() {

    if (this.refreshing) {
      return;
    }

    this.refreshing = true;
    /*
        if (!this.mediasService.medias || !this.pagesService.pages) {
          console.log('Wait 10 sec for pages and medias');
          setTimeout(() => {
            this.refreshing = false;
            this.refresh();
          }, 10000);
          return;
        }
    */
    this.slug = this.preferenceService.preference.startPage;

    try {
      this.media = this.page = null;
      this.media = this.mediasService.medias.filter(
        (x) => x.title === this.slug
      )[0];
      this.page = this.pagesService.pages.filter(
        (x) => x.slug === this.slug
      )[0];
      this.loaded = true;
    } catch (error) {
      console.error('Home refresh ERROR');
    }

    // Switch to admin if home page or media is missing
    if (!this.page || !this.media) {
      this.preferenceService.preference.adminMode = true;
    }

    this.refreshing = false;
  }

  async presentAlert(message: string) {
    const alert = await this.alertCtrl.create({
      // title: 'Information',
      message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            console.log('OK clicked');
          }
        }
      ]
    });
    await alert.present();
  }

  clickOnFirstImage() {
    this.mode = 'content';
    this.checkForScrollbar();
    /*
    this.router.navigateByUrl('/home', {
      skipLocationChange: true}
    );
    */
    // super.router.navigate(['/home'], {  });
  }
}

