import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  public preference: Preference;
  private observers: Array<any>;
  public lng = 'FR';

  preference$ = new BehaviorSubject<Preference>(null);
  public watchPreference(): Observable<Preference> { return this.preference$; }
  public peekPreference(): Preference { return this.preference$.value || this.preference; }
  public pokePreference(preference: Preference): void { this.preference$.next(preference); }

  constructor() {
    this.observers = [];

    this.refresh();
  }

  public refresh() {
    let preference = JSON.parse(localStorage.getItem('Preference'));
    if (!preference) {
      preference = {
        autoUpdate: true,
        confirmNewUpdate: false,
        backEndUrl: 'https://avapp.appmuseums.ch/site/', //'https://perroulaz.ch/WP/',
        timeout: 60,
        muteAllVideo: false,
        startPage: 'pa35',
        adminMode: true
      };
      // this.changeLanguage("FR");
      this.save(preference);
    }

    if (!this.preference) {
      this.preference = preference;
      this.pokePreference(this.preference);
    }
  }

  public async save(preference: Preference = null) {
    preference = preference || this.preference;

    const t = JSON.stringify(preference);
    console.log('save Preference', preference, t);

    // Validate data
    if (preference.timeout && preference.timeout < 10) {
      preference.timeout = 0;
    }

    // store
    localStorage.setItem('Preference', t);
    this.preference = preference;
    this.pokePreference(this.preference);
    console.log('saved Preference', t);
  }

  public languageSubscriber() {
    return (observer) => {
      this.observers.push(observer);
      return {
        unsubscribe() {
          this.observers.splice(this.observers.indexOf(observer), 1);
        },
      };
    };
  }

  public changeLanguage(lng: string) {
    this.lng = lng;
    this.observers.forEach((observer) => {
      observer.next(lng);
    });
  }
}

export class Preference {
  autoUpdate: boolean;
  confirmNewUpdate: boolean;
  backEndUrl: string;
  timeout?: number;
  startPage: string;
  muteAllVideo: boolean;
  adminMode: boolean;
}
