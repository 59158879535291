import { CacheService } from './../../services/cache.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { PageComponent } from '../../pages/page/page.component';

import { ActivatedRoute, Router } from '@angular/router';
import { PagesService, Page } from './../../services/pages.service';
import { MediasService, Media } from './../../services/medias.service';
import { PreferenceService } from '../../services/preference.service';
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { AppComponent } from '../../app.component';

// import { ok } from "assert";

@Component({
  selector: 'pages-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss'],
})

export class MediasComponent extends PageComponent 
      implements OnInit, OnDestroy, DoCheck {

  constructor(
    route: ActivatedRoute,
    router: Router,
    public pagesService: PagesService,
    public mediasService: MediasService,
    public preferenceService: PreferenceService,
    public differs: KeyValueDiffers,
    public appComponent: AppComponent,
  ) {
    super(
      route,
      router,
      pagesService,
      mediasService,
      preferenceService,
      differs,
      appComponent
    );
  }

  doRefresh(event) {
    this.mediasService.refresh(true);
  }
}
