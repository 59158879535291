import { env } from '../environments/environment';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
// import { TabsComponent } from './tabs.component';

import { AppRoutingModule } from './app-routing.module';

import { IonCustomScrollbarModule } from 'ion-custom-scrollbar'


// import { PagesComponent } from './pages/pages/pages.component';
/*
import { AngularFireModule,  } from '@angular/fire';

import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
*/
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TitleComponent } from './title.component';
import { PasswordComponent } from './password.component';

@NgModule({
  declarations: [
    AppComponent,
    TitleComponent,
    PasswordComponent,
  ],
  entryComponents: [PasswordComponent],
  imports: [HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    IonCustomScrollbarModule,
    /*
    AngularFireModule.initializeApp(env.firebaseConfig),
    AngularFireDatabaseModule, // +++ Required ?
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    */
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' })
  ],
  providers: [
    HttpClientModule,
    // AngularFirestore,
    // StatusBar,
    // SplashScreen,
    // PagesComponent,
  //  TabsComponent,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  // exports: [TabsComponent]
})
export class AppModule {}

/*
navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
        .then(function (registration)
        {
          console.log('Service worker registered successfully');
        }).catch(function (e)
        {
          console.error('Error during service worker registration:', e);
        });
*/


