import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PreferenceService } from './preference.service';

@Injectable({
  providedIn: 'root',
})
export class PagesService {

  //private myInterval: any;

  constructor(private http: HttpClient, private preferenceService: PreferenceService) {
    console.log('construtor: PagesComponent');

    this.mode$.next('media');

    this.refresh(false);
    // Survey of clear Storage
    // this.myInterval = setInterval(() => {
    //   console.log('Check Clear Storage Pages every 10 sec');
    //   if (localStorage.getItem('pages') === null) {
    //     console.log('Clear Storage detected');
    //     this.refresh(false);
    //   }
    // }, 10000);
  }

  public lng = 'FR';

  public refreshing: boolean;
  mode$ = new BehaviorSubject<string | null>(null);

  public pages: Array<Page>; // = new Array<Page>();
  pages$ = new BehaviorSubject<Array<Page>>(null);
  public watchPages(): Observable<Array<Page>> { return this.pages$; }
  public peekPages(): Array<Page> { return this.pages$.value; }
  public pokePages(): void { this.pages$.next(this.pages); }

  public watchMode(): Observable<string | null> { return this.mode$; }
  public peekMode(): string | null { return this.mode$.value; }
  public pokeMode(mode: string): void { this.mode$.next(mode); }

  public refresh(force: boolean) {
    console.log('Refresh Pages');

    if (this.refreshing) {
      return;
    }
    this.refreshing = true;

    const ips = localStorage.getItem('pages');

    if (!ips) {
      console.log('pages cleared');
      this.pages = [];
    } else {
      if (!this.pages) {
        this.pages = JSON.parse(ips);
      }
    }

    const url = this.preferenceService.preference.backEndUrl + 'wp-json/wp/v2/pages';
    this.http.get<string>(url).subscribe(
      (result) => {
        const pages: Array<object> = (result as unknown) as Array<Page>;
        const ps = pages.sort((a: Page, b: Page) => +a.id - b.id) as Array<Page>;
        let changed = false;
        ps.forEach((element) => {

          if (element.slug[0] === 'p') {
            let modified = true;

            const ms = this.pages.filter(
              (x) => x.id === element.id
            );
            if (ms.length > 0) {
              const m = ms[0].modified;
              modified = (m !== element.modified);
            }

            if (modified || force) {
              const page = this.mapElementToPage(element);

              let bFound = false;
              for (let index = 0; index < this.pages.length; index++) {
                const el = this.pages[index];
                if (el.id === page.id) {
                  this.pages[index] = page;
                  bFound = true;
                  changed = true;
                  break;
                }
              }
              if (!bFound) {
                this.updatePushFileIf(this.pages, page);
                changed = true;
                // pss.push(page);
              }
            }
          }
        });

        // this.pages = this.pages.concat(pss);
        if (changed) {
          console.warn('Pages changed');
          this.pokePages();
          localStorage.setItem('pages', JSON.stringify(this.pages));
        }

        this.refreshing = false;
      },
      (error) => {
        console.error(error);
        this.refreshing = false;
      }
    );

  }

  public updatePushFileIf(pages: Array<Page>, page: Page) {
    const exists = pages.find((ef) => {
      return ef.id === page.id;
    });

    if (!exists) {
      pages.push(page);
    }
  }

  // tslint:disable: no-string-literal
  private mapElementToPage(element: any): Page {
    const page: Page = {
      id: element.id,
      parent: element.parent,
      slug: element.slug,

      title: { FR: element.title['rendered'] },
      content: { FR: element.content['rendered'] },
      modified: element.modified
    };

    if (element.meta) {
      const meta = element.meta;
      page.title.EN = this.ml_f(meta, 'title', 'EN', page.title.FR);
      page.title.DE = this.ml_f(meta, 'title', 'DE', page.title.FR);
      page.content.EN = this.ml_f(meta, 'content', 'EN', page.content.FR);
      page.content.DE = this.ml_f(meta, 'content', 'DE', page.content.FR);

      page.content.FR = this.reviewLink(page.content.FR);
      page.content.EN = this.reviewLink(page.content.EN);
      page.content.DE = this.reviewLink(page.content.DE);
    }

    return page;
  }
  // tslint:enable: no-string-literal

  private ml_f(meta: string, type: string, lng: string, theDefault: string) {
    const lngs = {
      EN: 'en_GB',
      DE: 'de_DE'
    };

    const key = '_post_' + type + '_morelang_nml_' + lngs[lng];
    if (meta[key]) {
      return meta[key][0];
    } else {
      return theDefault;
    }
  }

  private reviewLink(html: string): string {

    const origin = window.location.origin;
    // origin = 'https://appmuseums.ch/AvApp/';

    const a = origin.split('/');
    a.splice(0, 3);

    const wbaseurl = '/' + a.join('/');
    const me = this;

    // Review href to go to local routing

    const regExpr = new RegExp(/<a([^>]*?)href\s*=\s*(['\"])([^\2]*?)\2\1*>/, 'gm');
    function r(match, p1, p2, p3) {
      const p = p3;
      let t = p3;
      if (p3.startsWith(me.preferenceService.preference.backEndUrl)) {
        const px = p3.replace(me.preferenceService.preference.backEndUrl, '');
        t = wbaseurl + 'page/' + px.slice(0, -1);
        return '<a class="mp_route" href="' + t + '">';
      }

      return '<a href="' + t + '">';
    }
    const body = html.replace(regExpr, r);

    return body;
  }
}

export class Page {
  id: number;
  parent?: number;
  slug: string;
  title: any;
  content: any;
  modified?: string;
}
