import { env } from '../environments/environment';
import { Component, OnInit, HostListener } from '@angular/core';

import { Platform, MenuController, NavController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { VersionCheckService } from './services/version-check.service';
import { PreferenceService } from './services/preference.service';
import { PagesService } from './services/pages.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: ['--background-checked {color: red;}'],
  providers: [VersionCheckService]
})

export class AppComponent implements OnInit {

  public appPages: any;
  public isAvApp: boolean = false;
  private myInterval: any;

  ngOnInit(): void {
    this.versionCheckService.initVersionCheck();
    this.resetInterval();
    this.isAvApp = this.isAvAppFn();
  }

  public resetInterval() {
    if (this.myInterval) {
      console.log('resetInterval');
      clearInterval(this.myInterval);
    }

    if (this.preferenceService.preference.timeout && this.preferenceService.preference.timeout >= 10) {
      this.myInterval = setInterval(() => {
        console.log('timeout to goto Home page after: ' + this.preferenceService.preference.timeout + ' sec');
        this.gotoRoot();
      }, this.preferenceService.preference.timeout /*sec*/ * 1000);
    }
  }

  public gotoRoot(event: any = null) {
    if (event) {
      event.preventDefault();
    }

    //if (env.production) {
    this.preferenceService.preference.adminMode = false;
    //}

    this.preferenceService.changeLanguage('FR');
    this.router.navigate(['/'], {});
    this.pagesService.pokeMode('media');
  }

  public gotoHome(event: any = null) {
    if (event) {
      event.preventDefault();
    }

    //if (env.production) {
    this.preferenceService.preference.adminMode = false;
    //}

    this.preferenceService.changeLanguage('FR');
    this.router.navigate(['/'], {});
    this.pagesService.pokeMode('content');
  }

  @HostListener('window:mousemove')
  handleMouseMove() {
    // console.log('mouseMove')
    this.resetInterval();
  }
  @HostListener('window:touchmove')
  handleTouchMove() {
    console.log('touchMove');
    this.resetInterval();
  }
  @HostListener('window:touchstart')
  handleTouchStart() {
    console.log('touchStart');
    this.resetInterval();
  }
  @HostListener('window:scroll')
  handleScroll() {
    console.log('scroll');
    this.resetInterval();
  }
  @HostListener('window:wheel')
  handleWheel() {
    //console.log('wheel');
    this.resetInterval();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown() {
    this.resetInterval();
  }
  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    public versionCheckService: VersionCheckService,
    public menuCtrl: MenuController,
    public preferenceService: PreferenceService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private router: Router,
    // public events: Events,
    private pagesService: PagesService,
  ) {


    this.appPages = [
      {
        title: 'Home',
        url: '/',
        icon: 'home'
      },
      {
        title: 'Pages',
        url: '/pages',
        icon: 'book'
      },
      {
        title: 'Medias',
        url: '/medias',
        icon: 'images'
      },
      {
        title: 'Settings',
        url: '/settings',
        icon: 'settings'
      }
    ];
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (env.useCordova) {
        // mp added
        // this.statusBar.styleDefault();
        // this.splashScreen.hide();
      }
    });
  }

  closeApp() {
    //alert('Close now');
    //window.close();
    //setTimeout(() => {window.close();}, 2000);
    //setTimeout(() => {window.close();}, 4000);
    //AppComponent.exi
    //navigator['app'].exitApp();
    //let url = window.location.href;
    //window.open(url, '_self', '');
    
    if (this.isAvApp) {
      const win = (window as any);
      win.chrome.webview.postMessage('QuitPlease');
    } else {
      // Try to close
      window.close();
    }
  }

  isAvAppFn(): boolean {
    const win = (window as any);
    const nav = navigator as any;
    let bPostMessage = false;

    if (win && nav) {
      const agent = nav.userAgent as string;
      if (agent && agent.indexOf("AvApp/") !== -1) {
        if (win.chrome && win.chrome.webview) {
          bPostMessage = true;
        }
      }
    }
    return bPostMessage;
  }

}
