import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HomePage } from './home.page';

import {UtilModule} from '../helper/Util.module';

@NgModule({
  declarations: [
    HomePage
  ],
  imports: [
    CommonModule,
    IonicModule,
    UtilModule,

    RouterModule.forRoot([{
      path: 'home',
      component: HomePage
    },
    {
      path: '',
      component: HomePage
    }], {onSameUrlNavigation: 'reload'})
  ]
})

export class HomePageModule {}
