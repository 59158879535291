import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { PagesService } from '../../services/pages.service';
import { PreferenceService } from '../../services/preference.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
})
export class PagesComponent {

  constructor(
    private route: ActivatedRoute,
    public pagesService: PagesService,
    public preferenceService: PreferenceService,
  ) {
  }

  doRefresh(event) {
    this.pagesService.refresh(true);
  }
}


