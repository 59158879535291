import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// import { APP_BASE_HREF } from "@angular/common";
import { HomePageModule } from './home/home.module';
import { PagePageModule } from './pages/page/page.module';
import { PagesPageModule } from './pages/pages/pages.module';
import { MediasPageModule } from './pages/medias/medias.module';
import { PreferencePageModule } from './pages/preference/preference.module';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([], { preloadingStrategy: PreloadAllModules }),
    HomePageModule,
    PagesPageModule,
    PagePageModule,
    MediasPageModule,
    PreferencePageModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
